<template>
  <div class="terms-of-use">
        <h1 class="text-3xl font-bold text-center">
            TERMOS DE USO
        </h1>
        <p>O ICHELLO (ou “nós” ou “Plataforma”) é uma plataforma da ICHELLO LTDA, que opera no site https://store.ichello.app/ (“Site”) e que destina, entre outras coisas, disponibilizar um marketplace e serviços (descritos abaixo) para o Usuário (ou “você”).</p>
        <p>Ao se cadastrar na plataforma ICHELLO ou ao usá-la ou acessá-la, você declara expressamente que tem conhecimento e concorda com os Termos de Uso do ICHELLO, inclusive com as Políticas de Privacidade e outros termos adicionais que, apesar de apresentadas em textos separados, são parte integrante destes. Qualquer dúvida quanto ao termo deve ser encaminhada para o e-mail contato@ichello.com.br</p>
        <p>Nós nos reservamos o direito de modificar ou descontinuar, temporária ou permanentemente, o Site (ou qualquer parte dele) e do presente Termo e suas Políticas com ou sem aviso prévio. Você concorda que não seremos responsabilizados por qualquer modificação, suspensão ou descontinuação do Site</p>

        <h2 class="font-bold text-2xl">
            QUEM SOMOS NÓS?
        </h2>
        <P>Nós somos uma plataforma para artistas (“Criadores”) e colecionadores (“Usuário” ou “você”) venderem e comprarem (“Transação”) Itens Digitais (“Obra”), também conhecidos como tokens não-fungíveis (“NFTs, incluindo colecionáveis, itens de jogos, arte digital e outros ativos digitais que são sustentados por uma blockchain.</P>
        <p>Os Itens Digitais possuem identificadores únicos e operam dentro de uma rede global de computadores chamada de blockchain, capaz de verificar a validade de todas as propriedades e transações, sendo praticamente impenetrável, podendo criar transações e as criptografando em uma cadeia progressiva formando-se um registro que praticamente não pode ser modificado sem que toda a cadeia seja modificada.  </p>
        <p>Essa tecnologia está revolucionando o mundo e não poderia ser diferente quando falamos de Arte!</p>

        <p>Se liga! Ao adentrar na nossa Plataforma você está ciente de que nós não compramos, vendemos ou possuímos propriedade intelectual sobre qualquer Item Digital publicado, somos apenas facilitadores de toda a Transação. Você, desde já, declara que está ciente e reconhece que nós somos apenas o provedor de tecnologia, não podendo ser responsabilizados por eventuais danos sofridos por você referente aos Itens Digitais e o seu Criador.</p>

        <h2 class="font-bold text-2xl">COMO USAR E NAVEGAR NA PLATAFORMA?</h2>
        <p>Idade Mínima: Os serviços oferecidos por nós somente podem ser contratados por pessoa habilitada à prática de todos os atos da vida civil (maiores de 18 anos no Brasil, ou regularmente emancipados), sendo vedado o cadastro de pessoas menores de idade. Qualquer usuário que se utilize ou se cadastre na plataforma, declara ser maior de 18 anos e ter plena capacidade do exercício de todos os atos da vida civil.</p>
        <p>Registro de Usuário: Qualquer um pode navegar pela plataforma sem registrar uma conta (“Conta”). Porém, para poder acessar e usar alguns dos nossos serviços, por exemplo, para se tornar um Criador ou um Colecionar, esse registro será exigido.</p>
        <p>Se você optar por se registrar na plataforma, você se obriga a preencher corretamente todos os dados solicitados no momento do cadastramento. Qualquer dado incorreto ou incompleto pode sujeitar você ao cancelamento de sua Conta.</p>
        <p>Se liga! Nos reservamos ao direito de não aprovar o cadastro de qualquer Usuário, se houver indício de que este está usando ou utilizará a plataforma em desacordo com suas políticas e com o presente Termo de Uso!</p>
        <p>Senha: É seu dever zelar pela confidencialidade da sua senha, sendo você o único responsável por ela. Nós não seremos responsabilizados por quaisquer perdas ou danos consequentes do uso indevido da sua senha por terceiros. </p>


        <h2 class="font-bold text-2xl">
            O QUE O USUÁRIO NÃO PODE FAZER?
        </h2>

        <p>Violar os direitos autorais do Criador, se dando como exemplo, mas não se limitando, a cópia, distribuição, download, armazenamento, modificação, transmissão, criação de produtos disponíveis de qualquer Obra encontrada no ICHELLO.</p>
        <p>Violar qualquer direito à propriedade intelectual, seja marca, patente, direito do autor ou direitos conexos da plataforma ICHELLO.</p>
        <p>Violar as medidas de segurança do ICHELLO, ou causar qualquer tipo de prejuízo ao ICHELLO e terceiros, ou ainda tentar obter vantagem com alguma falha de sistema da plataforma ICHELLO.</p>
        <p>Violar qualquer lei ou regulamento local, estadual, nacional ou internacional.</p>
        <p>Adulterar preços.</p>
        <p>Passar-se por pessoa ou entidade que não é;</p>
        <p>Usar SPAM ou qualquer outra prática de divulgação ou captação de informação inapropriada, caluniosa ou ilegal para promover qualquer Obra inserida nO ICHELLO;</p>
        <p>Praticar qualquer tipo de ofensa ou discriminação contra outros Usuários, Criadores ou terceiros.</p>


        <h2 class="font-bold text-2xl">
            COMO SER UM CRIADOR?
        </h2>

        <p>Se você ainda não faz parte da nossa comunidade de artistas talentosíssimos e quer se tornar um Criador, basta nos enviar sua solicitação para o e-mail: contato@ichello.com.br.</p>
        <p>
            Estamos ansiosos para conhecermos a sua arte e dividi-la com o mundo!
        </p>

        <h2 class="font-bold text-2xl">
            COMO IRÃO FUNCIONAR OS DIREITOS À PROPRIEDADE INTELECTUAL?
        </h2>

        <p>Se liga! As NFTs são um novo modelo de transacionar direitos (autorais e conexos) via blockchain. As NFTs são capazes de tornar um Item Digital único, podendo criar colecionáveis e permitindo o licenciamento da obra dentro do Marketplace.</p>
        <p>Titularidade do NFT X Titularidade dos Direitos Autorais:</p>
        <p>A titularidade sobre o direito autoral é diferente da titularidade sobre qualquer ativo digital (ou seja, um token não fungível ou “NFT”) no qual a obra está incorporada. O colecionador recebe um token criptográfico que representa a Arte Digital do Criador como uma propriedade, mas não são donos do trabalho criativo em si.</p>
        <p>O colecionador pode exibir e compartilhar o Item digital, mas não tem qualquer propriedade legal, direito ou título de quaisquer direitos autorais, marcas registradas ou outros direitos de propriedade intelectual sobre o Item.</p>
        <p>O Usuário declara e reconhece que tanto ele, quanto terceiros, não podem utilizar o Item Digital sem o consentimento prévio e expresso Criador,  especialmente para: (i) modificar, distorcer, ou realizar qualquer outra alteração do Item Digital que seria prejudicial à honra ou reputação do Criador ou da Plataforma (ii) usar a Item Digital para anunciar, comercializar ou vender qualquer produto ou serviço de terceiros; (iii) usar a Item Digital em conexão com imagens, vídeos ou outras formas de mídia que retratam ódio, intolerância, violência, crueldade ou qualquer outra coisa que possa ser considerada como discurso de ódio ou de outra forma infringir os direitos de terceiros; (iv) Incorporar o Item Digital em filmes, vídeos, videogames ou qualquer outra forma de mídia para fins comerciais, exceto na medida </p>
        <p>limitada em que tal uso seja expressamente permitido pelo Criador ou exclusivamente para uso pessoal e não comercial; (v) Tentar registrar direitos de propriedade intelectual incidentes sobre o Item Digital; (vi) Tentar cunhar, tokenizar ou criar um token criptográfico adicional representando o mesmo Item Digital, dentro ou fora da Plataforma; (vii) Falsificar, deturpar ou ocultar a autoria da Arte Digital; (viii) violar direitos de terceiros, de acordo com a legislação vigente, entre outros.</p>
        <p>Licenciamento para a Plataforma: Os Usuários e Criadores, na posição de titulares de um Item Digital, nos concedem expressamente a licença não exclusiva, mundial, atribuível, sublicenciável, perpétua e livre de royalties para fazer cópias, exibir, executar, reproduzir e distribuir o Item Digital em qualquer mídia, seja agora conhecida ou posteriormente descoberta para o amplo propósito de operar, promover, compartilhar, desenvolver, fazer marketing e anunciar a Plataforma ou qualquer outro propósito relacionado o ICHELLO, incluindo, sem limitação, o direito expresso de: (i) exibir ou executar o Item Digital na Plataforma, EM plataforma de terceiros, postagens em mídias sociais, blogs, editoriais, publicidade, relatórios de mercado, galerias virtuais, museus, ambientes virtuais, editoriais ou para o público; (ii) criar e distribuir Item Digital derivado digital ou física com base no Item Digital publicado no Site; (iii) indexação da Obra Digital em bases de dados eletrônicas, índices, catálogos; e (iv) hospedar, armazenar, distribuir e reproduzir uma ou mais cópias do Item Digital dentro de um sistema de manutenção de arquivos distribuído ou causar, direcionar ou solicitar que outros o façam.</p>
        <p>Plataforma: Você não se envolverá ou usará qualquer data mining, robôs, scraping ou coleta de dados ou métodos de extração semelhantes. Se você for impedido por nós de acessar a Plataforma, você concorda em não implementar quaisquer medidas para contornar tal bloqueio (por exemplo, mascarando seu endereço IP ou usando um endereço IP proxy). Qualquer uso da Plataforma ou do Conteúdo da Plataforma, exceto conforme especificamente autorizado neste documento, é estritamente proibido. A tecnologia e o software subjacente à Plataforma ou distribuídos em conexão com ela são de nossa propriedade, de nossas afiliadas e de nossos parceiros (o “Software”). Você concorda em não copiar, modificar, criar um trabalho derivado, fazer engenharia reversa, montagem reversa ou de outra forma tentar descobrir qualquer código-fonte, vender, atribuir, sublicenciar ou de outra forma transferir qualquer direito do Software. Quaisquer direitos não expressamente concedidos neste documento são reservados ao ICHELLO.</p>
        <p>Material de Terceiros: Sob nenhuma circunstância o ICHELLO será responsável por qualquer conteúdo ou material de terceiros. Você reconhece que nós não pré-selecionamos o conteúdo, mas que poderemos, a nosso exclusivo critério, recusar ou remover qualquer conteúdo que esteja disponível por meio da Plataforma. Nós teremos o direito de remover qualquer conteúdo que viole estes Termos de Serviço ou seja considerado pela Plataforma, a seu exclusivo critério, como de outra forma questionável. Você concorda que deve avaliar e assumir todos os riscos associados ao uso de qualquer conteúdo e à compra de qualquer </p>
        <p>Item Digital, incluindo qualquer confiança na precisão, integridade ou utilidade de tal conteúdo.</p>


        <h2 class="font-bold text-2xl">QUAIS OS MECANISMOS DE DENÚNCIA?</h2>
        <p>Reclamações sobre Violação de Direito Autoral: Nós respeitamos a propriedade intelectual de terceiros e pedimos aos nossos usuários que façam o mesmo. Se você acredita que seu trabalho foi copiado de uma forma que constitui violação de direitos autorais, ou que seus direitos de propriedade intelectual foram violados de outra forma, você deve notificar a Plataforma sobre sua reclamação no e-mail: contato@ichello.com.br .</p>
        <p>Reclamações sobre Conduta de Usuários: Nós não aceitamos qualquer conduta que viole a honra, imagem, privacidade, nome ou qualquer direito personalíssimo de terceiros ou que viole leis vigentes. Sendo assim, caso você tenha encontrado em nossa plataforma qualquer conduta de outro usuário em desacordo com a nossa cultura, você deve notificar a Plataforma sobre sua reclamação no e-mail: contato@ichello.com.br.</p>

        <h2 class="font-bold text-2xl">QUAIS NOSSAS FORMAS DE PAGAMENTO?</h2>
        <p>Nossa Plataforma possui dois meios de pagamento, sendo eles:</p>
        <p>TOKEN ICHELLO (ELLO): ativo digital desenvolvido em padrão BEP na Blockchain - Smart Chain, com hash do Smart Contract: 0xb4Cc9fcC99673661cb0727891693Ad7Ecbb94507. Sendo um token utility, desenvolvido para que os seus adquirentes possam fazer transações em forma de SWAP (troca), na plataforma de NFT https://store.ichello.app/.</p>
        <p>As Plataformas de gestão de pagamentos contidas no ICHELLO não são e nem se destinam a ser comparáveis aos serviços financeiros oferecidos por instituições bancárias ou administradoras de cartão de crédito, consistindo apenas em uma forma de facilitação e acompanhamento da realização de transações para pagamentos e recebimentos entre os Usuário, Criadores e ICHELLO. </p>
        <p>Além disso, todas as operações de comercialização serão verificadas pelas ferramentas de gestão de risco e poderão ser recusadas sempre que houver suspeita de fraude aos meios de pagamento ou infração à lei ou aos Termos de Uso.</p>

    
        <h2 class="font-bold text-2xl">E COMO FICA A NOSSA RELAÇÃO?</h2>
        
        <p>Privacidade: Nós somos responsáveis pela guarda dos dados de cadastro dos Usuários, conforme Políticas de Privacidade e nos termos da legislação aplicável;</p>
        <p>Atualização de Dados: Os Usuários serão responsáveis por manter seus dados cadastrais devidamente atualizados por pelo menos a cada 06 meses, sendo eles verdadeiros e legais.</p>
        <p>Inconsistências de Dados: No caso de quaisquer inconsistências dos dados detectada nós poderemos suspender a conta do Usuário até que esta seja regularizada.</p>
        <p>Responsabilidade sobre a Obra: O Criador é o responsável por todos os aspectos técnicos e de qualidade da Obra. Nós comunicaremos ao Criador quanto a qualquer problema e o daremos até 7 (sete) dias corridos para o envio de uma resposta ao ICHELLO. Caso o Criador não entre em contato com o ICHELLO nesse prazo, eventuais solicitações de reembolso poderão ser executadas de forma automática, observadas as disposições deste Termos de Uso.</p>
        <p>Melhores Técnicas: Nós nos comprometemos a aplicar as melhores técnicas para manter a plataforma em plenas condições de funcionamento. Entretanto, por razões técnicas e que fugirem ao nosso controle, a Plataforma poderá ficar indisponível por algum período. Os Usuários isentam o ICHELLO de responsabilidade por danos suportados em virtude de prejuízos resultantes de dificuldades técnicas ou falhas nos sistemas ou na internet. </p>
        <p>Transações: Todas as transações de compra e venda dos Itens Digitais só poderão acontecer dentro da Plataforma e com os meios de pagamento elencados nestes Termos de Uso.</p>
        <p>Responsabilização: Nós não nos responsabilizamos (i) por qualquer dano, prejuízo ou perda no equipamento do Usuário; (ii) por danos decorrentes de ataques de vírus ao equipamento do Usuário em decorrência do acesso, utilização ou navegação no ICHELLO ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio.</p>
        <p></p>
        <p>Nós não nos responsabilizamos pelo efetivo cumprimento das obrigações assumidas pelos Usuários.</p>
        <p>Não nos responsabilizamos pelo uso indevido da plataforma por terceiros, sejam Criadores ou Usuários, seja a que título for.</p>
        <p>Não nos responsabilizamos pelos materiais, informações ou conteúdo publicados em sites de terceiros, tampouco pela disponibilidade destes sites, competindo ao Usuário usar seu próprio discernimento, precaução e senso comum ao acessar sites de terceiros, devendo conferir as políticas de privacidade e os termos de uso aplicáveis</p>
        <p>Nós sempre tomaremos todas as medidas adequadas e possíveis para evitar qualquer violação aos direitos dos Usuários e dos Criadores, mas não poderemos ser considerados responsáveis por perdas ou danos derivados da violação de tais medidas por terceiros mal-intencionados.</p>
        <p>Ciência: Os Usuários reconhecem que a Plataforma pode conter links para outros sites da rede, o que não significa que esses sites sejam de propriedade ou operados pelo ICHELLO.</p>
        <p>Todas as informações fornecidas pelo Usuário ao ICHELLO são armazenadas e tratadas na forma indicada na Política de Privacidade.</p>
        <p>Dados: Os Usuários serão integralmente responsáveis por cumprir as leis e normativos aplicáveis relativos à proteção de dados pessoais, em especial a Lei Geral de Proteção de Dados Lei nº 13.709/18, assumindo a obrigação de obter o consentimento expresso e inequívoco de seus usuários relativamente a coleta, armazenamento, tratamento e processamento de dados pessoais, nos termos da legislação aplicável. Assim, o ICHELLO não será responsabilizado por eventual falha de seus Usuários.</p>
        <p></p>
        <p>Condutas Irregulares ou Ilegais: os Usuários reconhecem e autorizam que o ICHELLO bloquei suas contas e reporte aos órgãos de controle e fiscalização responsáveis os atos, condutas e práticas que contenham indícios de irregularidades e/ou ilegalidades, sem que isso gere, necessariamente, qualquer tipo de prejuízo ou dano moral e patrimonial ao Usuário reportado.</p>


        <h2 class="font-bold text-2xl">E AS PENALIDADES?</h2>

        <p>Qualquer conduta que viole o presente Termo De Uso e as Políticas a ele vinculadas poderão resultar na remoção de determinada Obra, e/ou a suspensão imediata do acesso à conta do Usuário, e/ou o bloqueio da conta do Usuário, completamente à critério do ICHELLO, não prejudicando qualquer medida judicial cabível.  </p>
        <p>Quando houver a remoção de Obra: transações relacionadas a ela poderão ter seus respectivos valores bloqueados e após a remoção não poderá ser feita qualquer nova transação.</p>
        <p>Quando houver a suspensão do acesso à conta do Usuário: transações relacionadas à Obras poderão continuar a ser realizadas, porém, o usuário não terá acesso a qualquer funcionalidade da conta, exemplo: nenhuma alteração poderá ser realizada nos dados cadastrais, dados bancários ou nos conteúdos cadastrados, tampouco poderá ser feito qualquer resgate de valores acumulados na respectiva conta.</p>
        <p>Nos casos de bloqueio à conta do cadastro do Usuário: todos os anúncios ativos e/ou ofertas realizadas serão automaticamente cancelados, não assistindo ao Usuário, por essa razão, qualquer direito de indenização ou ressarcimento.</p>
        <p>A ICHELLO, os USUÁRIOS ou quaisquer terceiros prejudicados poderão tomar as ações legais cabíveis em decorrência dos delitos ou contravenções de que sejam vítimas, ou ainda buscar o ressarcimento pelos prejuízos civis ou criminais por descumprimento dos Termos de Uso, sem que isso acarrete qualquer direito de indenização por parte da ICHELLO ao Usuário acionado.</p>


        <h2 class="font-bold text-2xl">ISENÇÃO DE RESPONSABILIDADE DA PLATAFORMA</h2>
        <p>O ICHELLO está completamente eximida de responsabilidade nos casos em que um Usuário ou terceiro inicie qualquer tipo de reclamação ou processo judicial contra outro Usuário ou Criador. É assim que declaram todos os Usuários.</p>
        <p>Em termos jurídicos, até onde é permitido por lei, ao ICHELLO, não é responsável por danos e prejuízos financeiros, materiais e morais, diretos ou indiretos, inclusive lucros cessantes, bem como danos físicos eventualmente sofridos pelos Usuários.</p>
        <p>Os preços dos ativos blockchain são extremamente voláteis. Flutuações no preço de outros ativos digitais podem afetar materialmente e adversamente o valor do NFT, que também podem estar sujeito a uma volatilidade de preço significativa.</p>
        <p>O Usuário reconhece que o custo de transação em tais tecnologias é variável e pode aumentar a qualquer momento, causando impacto em quaisquer atividades que ocorram no sistema blockchain. Você reconhece esses riscos e declara que a Plataforma não pode ser responsabilizada por tais flutuações ou aumento de custos.</p>
        <p>Existem riscos associados ao uso de criptomoedas, incluindo, mas não se limitando a, risco de hardware, software e conexões de Internet, o risco de introdução de software malicioso e o risco de que terceiros possam obter acesso não autorizado às informações armazenadas dentro de sua carteira virtual.</p>
        <p>O Usuário aceita e reconhece que a Plataforma não será responsável por quaisquer falhas de comunicação, interrupções, erros, distorções ou atrasos que você possa experimentar ao usar a Plataforma de Smart Contract no sistema Blockchain.</p>
        <p>O Usuário reconhece e entende que a criptografia é um campo em progresso os, avanços na quebra de código ou avanços técnicos, como o desenvolvimento de computadores quânticos, podem apresentar riscos para criptomoedas e ativos digitais, o que pode resultar no roubo ou perda de seus ativos digitais ou itens.</p>
        <p>Na medida do possível, ICHELLO atualiza os protocolos existentes para dar conta de quaisquer avanços na criptografia e incorporar medidas de segurança adicionais na Plataforma. Ao utilizar a Plataforma, você reconhece esses riscos inerentes.</p>

        <h2 class="font-bold text-2xl">RESCISÃO</h2>
        <p>O ICHELLO poderá e encerrar, a seu exclusivo critério, quaisquer serviços oferecidos e remover quaisquer Obra nele publicadas, exceto aquelas regularmente compradas e na posse do Usuário, podendo restringir o acesso de Usuários com ou sem justa causa ou notificação. Nesses casos o usuário/colecionador será solicitado a informar o endereço de carteira hathor para transferência do token (NFT) e deverá responder em até 60 dias para que a transferência seja feita adequadamente.</p>
        <p>Caso o Usuário deseje terminar sua conta, poderá apenas cessar seu acesso e uso dos serviços prestados pelo ICHELLO, que não será responsável, em qualquer hipótese, por manter ou devolver a conta, identificação ou senha do Usuário. </p>
        <p>Em qualquer caso, inclusive de rescisão ou solicitação judicial, o ICHELLO poderá, a seu exclusivo critério, reter total ou parcialmente o saldo existente na conta do Usuário, caso entenda que tal medida é necessária para garantir o pagamento, ressarcimento e reembolso devidos em razão de qualquer responsabilidade, obrigações ou dívida que o Usuário possa ter incorrido com o ICHELLO, Criadores e/ou terceiros.</p>


        <h2 class="font-bold text-2xl">FORO</h2>
        <p>Esperamos que não precisemos chegar a isso, mas para operações realizadas a partir das ferramentas disponibilizadas pelo ICHELLO fica eleito o foro da Comarca da Capital do Estado de Minas Gerais/BH como o único competente para dirimir eventuais controvérsias referentes à interpretação e ao cumprimento destes Termos de Uso e Política de Privacidade, independentemente de outro, por mais privilegiado que seja.</p>

        <h2 class="font-bold text-2xl">QUALQUER DÚVIDA, ENTRE EM CONTATO COM A GENTE!</h2>
        <p>Caso tenha qualquer dúvida em relação ao presente documento, o Usuário poderá entrar em contato através do <a href="mailto:contato@ichello.com.br">contato@ichello.com.br</a>.</p>


  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.terms-of-use {
    max-width: 900px;
    background: white;
    margin: 0 auto;
    border-radius: 16px;
    padding: 32px;
    margin: 60px auto;
}
.terms-of-use p {
    margin-bottom: 12px;
    line-height: 1.4;
}

.terms-of-use h1 {
    margin-bottom: 24px;
}
.terms-of-use h2 {
    margin-top: 24px;
    margin-bottom: 12px;
}
</style>